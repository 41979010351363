import * as React from 'react';
import { cn } from '@/lib/utils';
export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'default' | 'sm' | 'lg';
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  size = 'default',
  ...props
}, ref) => {
  return <input type={type} className={cn('flex w-full rounded-md border bg-input px-3 py-2', 'border-border', 'placeholder:text-muted-foreground', 'focus-visible:outline-none focus-visible:ring-2 focus-visible:border-input focus-visible:ring-offset-background  focus-visible:ring-ring/30 focus-visible:ring-offset-2', 'disabled:cursor-not-allowed disabled:opacity-50', {
    'h-10': size === 'default',
    'h-8': size === 'sm',
    'h-12': size === 'lg'
  }, className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';
export { Input };